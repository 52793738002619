<template>
  <v-row dense>
    <v-col cols="12">
      <v-data-table
          v-if="medicalTeams && medicalTeams.length"
          class="medical-staff-data-table"
          :search="appBarSearch"
          :no-data-text="$t('no-data-available')"
          :items="medicalTeams"
          fixed-header
          :loading="loading"
          dense
          :headers="headers"
          hide-default-header
          :height="pageHeight(160)"
          group-by="[id]"
          ref="table"
          :footer-props= "getDataTableFooterProps('medicalTeamTable')"
          :items-per-page="-1"

      >
        <template v-slot:[`group.header`]="{ group, headers, items, isOpen, toggle  }">
          <td   @click="toggle" class="pa-2 text-left medical-teams-data-table pointer" :colspan="7">
            <v-row dense class="my-n3 d-flex align-center">
              <v-col cols="4">
                <v-btn small icon :ref="group" :data-open="isOpen" class="color-black">
                  <v-icon  small v-if="isOpen" color="primary">mdi-arrow-down-drop-circle</v-icon>
                  <v-icon small v-else color="primary">mdi-arrow-right-drop-circle</v-icon>
                </v-btn>
                <span class="table-font-size">{{ items[0].patientFullname }} {{ $t("medicalTeam") }}</span>
              </v-col>
              <v-col cols="2">
                <v-chip pill x-small color="primary" class="ma-2 text--black custom-chip-size" label>
                  {{ items[0].membersList.length }} {{ items[0].membersList.length > 1 ? $t('members') : $t('member')}}
                </v-chip>
              </v-col>
              <v-col class="d-flex align-center">
                <img class="mr-1" height="18px" :src="patient_black" />
                <span class="table-font-size">{{ items[0].patientFullname }}</span>
              </v-col>
              <v-col class="text-right">
                <v-tooltip left color="primary">
                  <template v-slot:activator="{ on }">
                    <v-icon size="18" @click="navigateToPatientDetails(items[0].patientId)" color="main_black" v-on="on" style="margin-top:5px;">mdi-open-in-new</v-icon>
                  </template>
                  <span>{{ $t('show-patient-details') }}</span>
                </v-tooltip>
                <v-menu left offset-y transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="black" v-bind="attrs" v-on="on">
                      <v-icon class="mt-1">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="pa-0">
                    <v-list-item link dense @click="synchronizeDrawers(items[0])">
                      <v-icon  size="18" color="main_black">mdi-pencil</v-icon>
                      <v-list-item-content class="font-size14 font-weight-medium">{{ $t("edit") }}</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-slot:item="{ item }">
          <tbody>
          <td v-for="(header,index) in headers" :key="index" class="secondary" :class="header.id===6 ? 'text-right pr-2' : 'text-left'" style="width:400px;">
            <span class="font-weight-medium font-size11" :class="header.id ===1 ? 'pl-2' :'' ">{{ header.text }}</span>
          </td>
          <tr v-for="(member) in item.membersList" :key="member.patientId">
            <td class="text-left pl-2 color-black billings-border-bottom font-size12-5">

                  <span class="font-weight-medium text-left font-size12-5" >{{ member.firstName }} {{member.lastName}}</span>
            </td>
            <td class="table-font-size billings-border-bottom">
              <img  v-if="member.roleId === 4" :src="getNurseIcon(member.status)"  height="17px"/>
              <img  v-else-if="member.roleId === 3" :src="getDoctorIcon(member.status)"  height="17px"/>
              {{ member.roleId === 3 ? $t('doctor') : $t('nurse') }}
            </td>
            <td class="table-font-size billings-border-bottom">
              <v-icon x-small color="main_black" class="mr-1 ">mdi-email</v-icon>{{ member.email }}
            </td>
            <td class="table-font-size billings-border-bottom">
              <v-icon x-small color="main_black" class="mr-1">mdi-phone</v-icon>{{ member.phone }}
            </td>
            <td class="table-font-size billings-border-bottom">{{ item.hospitalName }}</td>
            <td class="billings-border-bottom">
                <span v-if="member.id === item.primaryDoctorId">
                    <v-icon color="black" class="mdi mdi-check-circle pr-6 mdi-18px float-right"></v-icon>
                </span>
            </td>

          </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { patientBlack } from '@/assets';
import { getDataTableFooterProps, getDoctorIcon, getNurseIcon, toggleHeaders } from '@/utils/utilities';
export default {
	data () {
		return {
      loading: true,
      headers: [
        { id: 1, text: this.$t('name'), sortable: false, class: 'color_header ', align: 'start', value: 'patientFullname' },
        { id: 2, text: this.$t('header-role'), sortable: false, class: 'color_header', value: 'firstName' },
        { id: 3, text: this.$t('header-email'), sortable: false, class: 'color_header' },
        { id: 4, text: this.$t('header-phone'), sortable: false, class: 'color_header' },
        { id: 5, text: this.$t('header-hospital-name'), sortable: false, class: 'color_header' },
        { id: 6, text: this.$t('primary-doctor'), sortable: false, class: 'color_header', align: 'end' },
      ],
			patient_black: patientBlack,
		};
	},
	computed: {
		...mapState({
      userData: (state) => state.authentication.userData,
      medicalTeams: (state) => state.medicalteam.medicalTeam,
		}),
		...mapGetters({
			getUser: 'authentication/getUser',
			pageHeight: 'pageHeight',
			appBarSearch: 'filterbar/getSearch',
		}),

	},
	async mounted () {
		await this.getMedicalTeams();
    this.toggleHeaders();
	},
  methods: {
    getNurseIcon,
    getDoctorIcon,
    toggleHeaders,
    getDataTableFooterProps,
		navigateToPatientDetails (patientId) {
			this.$router.push({ name: 'Patient Details', params: { id: patientId } });
		},
		async synchronizeDrawers (item) {
      const currentHospital = localStorage.getItem('selectedHospitalAssignedId');
      await this.$store.dispatch('medicalteam/getMedicalTeamForPatient', item.patientId)
          .then(() => {
            this.$store.dispatch('hospitals/getRelatedMedicalStaffForHospital', currentHospital);
            this.$store.commit('SET_UPDATE_DETAILS_DRAWER', { item, updateDrawer: true, page2: 'medicalteam' });
            this.loading = false;
          });
		},
    async getMedicalTeams () {
      this.loading = true;
      await this.$store.dispatch('medicalteam/getMedicalTeams', this.getUser.hospitalAssignedData.id).then(res => {
        this.loading = false;
      });
    },
	},
};
</script>
